
require('./styles/index.scss');

var $ = require('jquery');
var tabby = require('./js/tabby');
var MenuAnchor = require('./js/navigation-2')($);
var MicroModal = require('micromodal').default;

require('./js/site-animations')();
require('./js/form')();


tabby.init({
    selectorToggle: '[data-tab]', // Tab toggle selector
    selectorToggleGroup: '[data-tabs]', // Tab toggle group selector
    selectorContent: '[data-tabs-pane]', // Tab content selector
    selectorContentGroup: '[data-tabs-content]', // Tab content group selector
    toggleActiveClass: 'is-active', // Class added to active toggle elements
    contentActiveClass: 'is-active', // Class added to active tab content areas
    initClass: 'js-tabby', // Class added to <html> element when initiated
    stopVideo: true, // [Boolean] If true, stop videos when tab closes
    callback: function (tabs, toggle) {
        
    } // Function that's run after tab content is toggled
});

$('.js-open-sign-up-modal').on('click', function (e) {
    var groupId = $(this).attr('data-group-id');
    MicroModal.show('sign-up-modal');
    $('#groups').val(groupId).change();
})

function toggleAccordion (e) {
    var activeItem = e.target.closest('.js-accordion-item');
    if (!activeItem) return;
    var items = e.currentTarget.querySelectorAll('.js-accordion-item');

    items.forEach(function (el) {
        el.classList.remove('is-active');
    })

    activeItem.classList.add('is-active');
}

function initAccordion(accordions) {
    accordions.forEach(function (el) {
        el.addEventListener('click', function (e) {
            toggleAccordion(e);
        })
    })
}

initAccordion(document.querySelectorAll('.js-accordion'));

function scrollBarWidth () {
    var div = document.createElement('div');

    div.style.overflowY = 'scroll';
    div.style.width = '50px';
    div.style.height = '50px';
    div.style.visibility = 'hidden';

    document.body.appendChild(div);
    var scrollWidth = div.offsetWidth - div.clientWidth;
    document.body.removeChild(div);

    return scrollWidth;
};

$('.js-nav').MenuAnchor({
    window: window.top,
    pluralId: 'section-',
    contentPostfix: '-content',
    headerH: $('#header').height() + 20,
    scroll: true
});

$('#section-8').on('click', function () {
//    $('#join-us-message').css('display', 'block');
    $('#subscribe-form').find('input').focus();
});

MicroModal.init({
    onShow (modal) {
        document.querySelector('body').classList.add('is-modal-opened');
        $('html, #header').css('padding-right', scrollBarWidth() + 'px');
    },
    onClose (modal) {
        document.querySelector('body').classList.remove('is-modal-opened');
        $('html, #header').css('padding-right', 0);
    }
});

$('.js-choose-pill').on('click', function (e) {
    $('html, body').animate({
        scrollTop: $(this.getAttribute('href') + '-content').offset().top - 114
    }, 1000);
});

// Mobile menu
$('.js-toggle-mobile-menu').on('click', function () {
    if ($('.root').hasClass('is-menu-opened')) {
        $('.root').removeClass('is-menu-opened');
        $(this).removeClass('is-open');

        return;
    }
    $('.root').addClass('is-menu-opened');
    $(this).addClass('is-open');
});

$('.js-nav a').on('click', function () {
    if ($('.root').hasClass('is-menu-opened')) {
        $('.root').removeClass('is-menu-opened');
        $('.js-toggle-mobile-menu').removeClass('is-open');
    }
})

// Check permission
if (localStorage.getItem("jwt-token") !== null) {
    var linkTemplate = $('#dashboard-link').html();
    $('.js-actions-nav').addClass('is-logged-in');
    $('.js-actions-nav').append(linkTemplate);
}