var $ = require('jquery');
var MicroModal = require('micromodal').default;

module.exports = function () {
  // var userType = {
  //   ADVERTISER_ID: 7,
  //   PUBLISHER_ID: 5,
  //   BOUNTY_HUNTER_ID: 11,
  //   ICO_MANAGER: 21,
  //   _current: 7
  // };

  function clearForm(form) {
    var formElements = Array.prototype.slice.call(form.elements).filter(el => { return (el.tagName === 'INPUT' || el.tagName === 'TEXTAREA' || el.tagName === 'SELECT') });
    
    if (form.querySelector('.g-recaptcha')) grecaptcha.reset(window[form.querySelector('.g-recaptcha').id]);

    formElements.forEach(el => {
      el.value = '';

      if (el.type === 'checkbox' || el.type === 'radio') {
        el.checked = false;
      }
    });
  }

  function generateFormJson(form) {
    var formJson = {};
    var formElements = Array.prototype.slice.call(form.elements).filter(el => { return (el.tagName === 'INPUT' || el.tagName === 'TEXTAREA' || el.tagName === 'SELECT') });
    formElements.forEach(el => {
      var fieldName = el.name;
      formJson[fieldName] = (el.value === '') ? null : el.value;
      
      if (el.type === 'checkbox' || el.type === 'radio') {
        formJson[fieldName] = el.checked;
      }
    });
    if (form.querySelector('.g-recaptcha')) {
      formJson['g-recaptcha-response'] = grecaptcha.getResponse(window[form.querySelector('.g-recaptcha').id]);
    }
    return JSON.stringify(formJson);
  }


  function serializationSignUpForm(form) {
    var signUpFields = JSON.parse(generateFormJson(form));

    var endObj = {
      'email': signUpFields.email,
      'groups': [signUpFields.groups],
      'plainPassword': {
        'first': signUpFields['password'],
        'second': signUpFields['password-confirm']
      },
      'email-confirm': signUpFields['email-confirm'],
      'agreement': signUpFields['agreement'],
      'g-recaptcha-response': signUpFields['g-recaptcha-response']
    };

    return JSON.stringify(endObj);
  }

  function deSerializationSignUpFormResponse(json) {
    var deserializeObj = {};
    (json['groups']) ? deserializeObj.groups = json['groups'] : false;
    (json.plainPassword) ? deserializeObj.password = json.plainPassword['first'] : false;
    (json.plainPassword) ? deserializeObj['password-confirm'] = json.plainPassword['second'] : false;
    (json['email']) ? deserializeObj['email'] = json['email'] : false;
    (json['email-confirm']) ? deserializeObj['email-confirm'] = json['email-confirm'] : false;
    (json['agreement']) ? deserializeObj['agreement'] = json['agreement'] : false;
    (json['g-recaptcha-response']) ? deserializeObj['g-recaptcha-response'] = json['g-recaptcha-response'] : false;

    return deserializeObj;
  }

  function showErrors(form, errors) {
    errors.forEach((el) => {
      var $erroredFieldset = $(form).find(`[name="${el.field}"]`).parents('fieldset');

      $erroredFieldset.addClass('is-error');
      $erroredFieldset.find('.input__error').html(el.message);
    })
  }

  function showThankModal (title, message) {
    var modal = document.querySelector('#thank-you-modal');

    modal.querySelector('#thank-you-modal-title').innerHTML = title;
    modal.querySelector('#thank-you-modal-message').innerHTML = message;

    MicroModal.show('thank-you-modal');
  }

  function setupSignInForm () {
    var LOGIN_URL = 'https://old.cryptotraffic.net/api_login';

    var signInView = document.getElementById('sign-in-modal');
    var signInForm = signInView.querySelector('form');

    signInForm.querySelector('#authentificator-check-1').addEventListener('change', function (event) {
      if (event.target.checked) {
        signInForm.querySelector('.js-authentificator-code').classList.remove('is-hide');
        return;
      }
      signInForm.querySelector('.js-authentificator-code').classList.add('is-hide');
    })

    signInForm.addEventListener('submit', function (e) {
      e.preventDefault();

      $.post(
        LOGIN_URL,
        generateFormJson(signInForm))
        .done(function (response) {
          if (response.success) {
            window.location.href = response.redirect;
            clearForm(signInForm);
            return;
          }
          showErrors(signInForm, response.errors);
        });

      return;
    });
  }

  function setupSignUpForm () {
    var signUpView = document.getElementById('sign-up-modal');
    var signUpForm = signUpView.querySelector('form');
  
    var REGISTRATION_URL = 'https://old.cryptotraffic.net/api/v0/registration';

    signUpForm.addEventListener('submit', function (e) {
      e.preventDefault();

      $.post(
        REGISTRATION_URL,
        serializationSignUpForm(signUpForm))
        .done(function (response) {
          if (response.success) {
            window.location.href = response.redirect;
            clearForm(signUpForm);
            return;
          }
          showErrors(signUpForm, deSerializationSignUpFormResponse(response.errors));
        });

      return;
    });
  }

  function setupContactUsForm () {
    var CONTACT_US_URL = 'https://api.aechannel.com/cryptotraffic/users/contact';

    var contactUsForm = document.getElementById('contact-us-form');

    contactUsForm.addEventListener('submit', function (e) {
      e.preventDefault();

      $.post(
        CONTACT_US_URL,
        generateFormJson(contactUsForm))
        .always(function(response) {
          if (response.status === 200) {
            clearForm(contactUsForm);
            showThankModal('Thank you for your request!', 'We will contact you as soon as possible!');
          } else {
            showErrors(contactUsForm, response.responseJSON.error.errors);
          }
        });

      return;
    });
  }

  function setupSubscribeForm () {
    var NEWSLETTER_URL = 'https://api.aechannel.com/cryptotraffic/users/subscribe';

    var subscribeForm = document.getElementById('subscribe-form');

    subscribeForm.addEventListener('submit', function (e) {
      e.preventDefault();

      $.post(
        NEWSLETTER_URL,
        generateFormJson(subscribeForm))
        .always(function(response) {
          if (response.status === 200) {
            clearForm(subscribeForm);
            showThankModal('Thank You For Subscribing!', '');
          } else {
            var error = response.responseJSON.error ? response.responseJSON.error.errors[0].message : 'Something went wrong, please try again later';
            subscribeForm.querySelector('fieldset').classList.add('is-error');
            subscribeForm.querySelector('.input__error').innerHTML = error;
          }
        });

      return;
    });
  }

  setupSignUpForm();
  setupSignInForm();
  setupContactUsForm();
  setupSubscribeForm();
};
